.main-footer {
  margin-top: 100px;
  padding-bottom: 30px;
  .container {
    display: flex;
    justify-content: space-between;
  }
}
.main-footer__phone-block {
    span {
        color: #fff;
        font-family: $g-reg;
        font-size: .875rem;
    }
    a {
        display: block;
        color: #fff;
        font-family: $g-bold;
        font-size: .875rem;
    }
}
.main-footer__soc-block {
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    margin-right: 15px;
    color: #fff;
    font-family: $g-reg;
    font-size: .875rem;
  }
}
.soc-block__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.soc-block__icon {
  width: 36px;
  height: 36px;
  background-image: url("../img/icons.svg");
  background-size: cover;
  a {
    display: block;
    width: 36px;
    height: 36px;
  }
}
.vk-icon {

  background-position: 0 0;
}
.inst-icon {
  background-position: 0 504px;
  margin-left: 13px;
  margin-right: 13px;
}
.ok-icon {
  background-position: 0 792px;
}

@media #{$tablet-widths} {
  .main-footer__phone-block {
      span {
        font-size: .7rem;
      }
      a {

          font-size: .7rem;
      }
  }
  .main-footer__soc-block {
    flex-direction: column;
    span {
      margin-right: 0;
      margin-top: 5px;
      margin-bottom: 2px;
      font-size: .7rem;
    }
  }
  .soc-block__icon {
    width: 15px;
    height: 15px;
    a {
      display: block;
      width: 15px;
      height: 15px;
    }
  }
  // .vk-icon {
  //   background-position: 0 15px;
  // }
  .inst-icon {
    background-position: 0 526px;
  }
  .ok-icon {
    background-position: 0 781px;
  }
}
@media #{$phone-widths} {
  .main-footer {
    margin-top: 50px;
    padding-bottom: 15px;
  }
}
