@include border-box;
body,
html {
    width: 100%;
    height: 100%;
    line-height: inherit;
    font-size: 1rem;
}
body {
  background-color: #043339;
}
.main-wrapper {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    width: 100%;
    // background-image: url("../img/second-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
}
.container {
    margin: 0 80px;
}
.content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  .page {
    width: 100%;
  }
}
a {
    text-decoration: none;
    color: #000;
}
button {
    border: none;
    background-color: transparent;
    cursor: pointer;
}
img{
  display: block;
  max-width: 100%;
  height: auto;
}

@media #{$desktop-xs-widths} {
  .container {
    margin: 0 40px;
  }
}
@media #{$tablet-widths} {
  .container {
    margin: 0 20px;
  }
}
@media #{$phone-widths} {
  .container {
    margin: 0 5px;
  }
}

.eye {
  animation-name: eye;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
