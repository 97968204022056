.price-block {
  color: #fff;
  font-family: $g-reg;
}
.price-block__wrapper {
  width: 555px;
  margin: auto;
}
.price-block {
  h1 {
    color: #fff;
    font-family: $g-black;
    font-size: 3rem;
    text-align: center;
  }
  h2{
    margin-top: 25px;
    margin-bottom: 45px;
    color: #fff;
    font-family: $g-light;
    font-size: 1.875rem;
    text-align: center;
    line-height: 1;
    sup {
      vertical-align: baseline;
      position: relative;
      top: -0.1em;
      left: -0.3em;
      color: #ff0000;
    }
  }
}
.price-table {
  margin-bottom: 100px;
}
.price-block__table-offer {
  margin-bottom: 50px;
}
.price-row {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}
.cell-field {
  color: #fff;
  font-family: $g-reg;
  font-size: 1.125rem;
  line-height: 1.4;
}
.cell-value {
  color: #fff;
  font-family: $g-reg;
  font-size: 1.125rem;
  line-height: 1.4;
}
.cell-border {
  flex: 0%;
  margin-left: 18px;
  margin-right: 12px;
  border: 1px dashed #17778b;
}
.price-table__footnote {
  margin-top: 50px;
  color: #fff;
  font-family: $g-reg;
  font-size: .875rem;
  sup {
    color: #ff0000;
  }
}
.price-block__special-offer {
  margin-bottom: 100px;
  h3{
    font-family: $g-light;
    font-size: 1.875rem;
    margin-bottom: 0;
    text-align: center;
  }
  h4{
    margin-top: 25px;
    margin-bottom: 25px;
    text-align: center;
    font-size: 1.25rem;
    text-align: center;
  }
}
.special-offer__descr {
  margin-top: 50px;
  p {
    font-size: 1.125rem;
  }
}
.special-offer__list {
  margin-top: 35px;
  margin-bottom: 35px;
  li {
    font-size: 1.125rem;
    line-height: 24px;
  }
}
.price-block__schedule {
  h3{
    margin-bottom: 25px;
    font-family: $g-light;
    font-size: 1.875rem;
    text-align: center;
  }
  caption {
    margin-bottom: 22px;
    font-family: $g-light;
    font-size: 1.25rem;
  }
  td {
    font-size: 1.125rem;
    line-height: 1.5;
  }
}
.weekdays {
  margin-bottom: 50px;
}
.cell-time {
  padding-right: 50px;
}
@media #{$desktop-xs-widths} {
  .price-block__wrapper{
    width: auto;
    margin: 0 20px;
  }
}
@media #{$tablet-widths} {
  .price-block__wrapper{
    width: auto;
    margin: 0 5px;
  }
  .price-block {
    h1 {
      font-size: 2rem;
    }
    h2{
      margin-top: 25px;
      margin-bottom: 45px;
      font-size: 2rem;
      line-height: 1;
      sup {
        top: -0.1em;
        left: -0.3em;
      }
    }
  }
  .cell-field {
    font-size: .8rem;
    line-height: 1.4;
  }
  .cell-value {
    font-size: .8rem;
    line-height: 1.4;
  }
  .special-offer__descr  {
    p{
      font-size: 1rem;
    }
  }
  .special-offer__list {
    li {
      font-size: 1rem;
    }
  }
  .price-block__schedule {
    td {
      font-size: 1rem;
      line-height: 1.5;
    }
  }
  .cell-time {
    padding-right: 10px;
  }
}
@media #{$tablet-widths} {
  .price-block__schedule {
    td {
      font-size: .72rem;
      line-height: 1.5;
    }
  }
}
