.main-header {
  margin-bottom: 100px;
    padding-top: 30px;
    .container {
      display: flex;
      flex-flow:row wrap;
      justify-content: space-between;
    }
}
.main-header__nav {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    justify-content: space-between;
}
.list-items {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    .current_page_item {
      a {
        color: #17778b;
      }
    }
    li {
        a {
            margin-left: 46px;
            margin-right: 46px;
            color: #fff;
            font-family: $g-bold;
            &:hover {
                color: #36a3dd;
            }
            &:first-child {
                margin-left: 0;
            }
        }
    }
}

.main-header__phone-block {
    a {
        display: inline-block;
        color: #fff;
        font-family: $g-bold;
        font-size: 1.125rem;
        span {
            display: block;
            font-family: $g-reg;
            font-size: 1.125rem;
            text-align: right;
        }
    }
}
@media #{$desktop-sm-widths} {
  .list-items {
    li{
        a {
            margin-left: 16px;
            margin-right: 16px;
        }
    }
  }
}
@media #{$desktop-xs-widths} {
  .list-items {
    li{
        a {
            font-size: 0.875rem;
        }
    }
  }

    .main-header__phone-block {
        a {
            font-size: 1rem;
            span {
                font-size: 1rem;
            }
        }
    }
}
@media #{$tablet-widths} {
    .list-items {
        flex-direction: column;
        li {
            margin: 7px 0;
            &:first-child {
                margin-top: 0;
            }
        }
    }

    .main-header__phone-block {
        a {
            font-size: 0.7rem;
            span {
                font-size: 0.8rem;
            }
        }
    }
}
@media #{$phone-widths} {
  .main-header {
    margin-bottom: 50px;
  }
  .main-header__nav {
    flex-basis: 50%;
  }
  .main-header__phone-block {
    text-align: right;
    flex-basis: 50%;

  }
    .list-items__item-nav {
        margin: 7px 0;
        &:first-child {
            margin-top: 0;
        }
    }
}
