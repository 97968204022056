@mixin border-box {
	*,
	*:after,
	*:before {
		margin: 0;
		padding: 0;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}
}

@mixin clearfix {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

//===========================================

// =============================================================================
// String Replace
// =============================================================================

@function str-replace($string, $search, $replace: "") {
	$index: str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}

	@return $string;
}

// =============================================================================
// Font Face
// =============================================================================

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
	$src: null;

	$extmods: (
		eot: "?",
		svg: "#" + str-replace($name, " ", "_")
		);

	$formats: (
		otf: "opentype",
		ttf: "truetype"
		);

	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
		$src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
	}

	@font-face {
		font-family: quote($name);
		font-style: $style;
		font-weight: $weight;
		src: $src;
	}
}

// =============================================================================
// viewports
// =============================================================================

@function gh($target) {
	$vh-context: (1080 * 0.01) * 1px;
	@return ($target / $vh-context) * 1vh;
}

@function gw($target) {
	$vw-context: (1920 * 0.01) * 1px;
	@return ($target / $vw-context) * 1vw;
}

@mixin vedeoRespon {

	.embed-responsive {
		position: relative;
		display: block;
		height: 0;
		padding: 0;
		overflow: hidden;
	}
	.embed-responsive .embed-responsive-item,
	.embed-responsive iframe {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
	}
	.embed-responsive-16by9 {
		padding-bottom: 56.25%;
	}
}

// =============================================================================
// rem to px
// =============================================================================



@function rem($pxs) {
  @if type_of($pxs) == list {
    $rem: ();
    @each $px in $pxs {
      $rem: append($rem, $px / 16 * 1rem);
    }
  } @else {
    $rem: $pxs / 16 * 1rem;
  }

  @return $rem;
}
