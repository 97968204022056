.about-us {
  width: 100%;
}
.about-us__descr {
  color: #fff;
  text-align: center;
  h1 {
    font-family: $g-black;
    font-size: 3rem;
  }
  h2 {
    font-family: $g-light;
    font-size: 1.875rem;
  }
}
.about-us__what-is {
  display: table;
  width: 100%;
  min-height: 300px;
  margin-top: 45px;
  color: #fff;
  font-family: $g-light;
}
.about-us__img-block {
  display: table-cell;
  width: 50%;
  background-image: url("../img/about-img.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}
.about-us__list-services {
  display: table-cell;
  width: 50%;
  padding: 10px;
  border: 1px solid #17778b;
  text-align: center;
  vertical-align: middle;
  ul {
    display: inline-block;
    vertical-align: middle;
  }
  li {
    line-height: 1.5;
    font-size: 1.125rem;
    text-align: left;
    font-family: $g-reg;
  }
}
.about-us__text-block {
  max-width: 555px;
  margin:45px auto 0;
  color: #fff;
  font-family: $g-reg;
  p{
    margin-bottom: 35px;
    font-size: 1.125rem;
    line-height: 1.4;
  }
}
.about-us__time {
  display: flex;
  max-width: 750px;
  align-items: center;
  justify-content: center;
  margin: 0 auto 45px;
  padding: 22px 56px;
  border: 2px solid #fff;
  color: #fff;
  p{
    color: #fff;
    font-family: $pn-bold;
    font-size: 1.125rem;
  }
  .fa-clock-o {
    margin-right: 25px;
    font-size: 3rem;
  }
}
.header-gallery {
  margin-top: 95px;
  margin-bottom: 45px;
  color: #fff;
  font-family: $g-light;
  font-size: 1.875rem;
  text-align: center;
}
.about-us__gallary {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1055px;
  margin: auto;
  .swiper-button-prev {
    position: relative;
    left: auto;
    right: auto;
    background-image: url('../img/arrow-img-left.svg');
    &:hover{
      transform: scale(.8);
      transition: 700ms ease;
    }
  }
  .swiper-button-next {
    position: relative;
    left: auto;
    right: auto;
    background-image: url('../img/arrow-img.svg');
    &:hover{
      transform: scale(.8);
      transition: 700ms ease;
    }
  }
}
.swiper-container {
    max-width: 945px;
    height: 100%;
    .swiper-slide {
      z-index: 1;
      &:hover{
        transform: scale(1.2);
        z-index: 999;
      }
    }
    img{
      width: 100%;
    }
}
.about-us__review {
  padding-top: 95px;
  max-width: 650px;
  margin: auto;
  h2 {
    padding-bottom: 50px;
    font-family: $g-light;
    font-size: 1.875rem;
    color: #fff;
    text-align: center;
  }
}
.item-review {
  @include clearfix;
  display: flex;
  margin-bottom: 80px;
  &:last-child{
    margin-bottom: 0;
  }
}
.item-review__img {
  img {
    max-width: inherit;
  }
}
.item-review__text-review {
  padding-left: 30px;
}
.item-review__descr {
  color: #fff;
  font-family: $g-bold;
  font-size: 1.25rem;
  line-height: 1;
  h3 {
    line-height: .8;
  }
}
.item-review__comment {
  padding: 28px 0;
  color: #fff;
  font-family: $g-reg;
  font-size: .875rem;
}
.item-review__author {
  color: #fff;
  font-family: $g-bold;
  font-size: 0.75rem;
  line-height: 1;
}
@media #{$desktop-sm-widths} {
  .about-us__list-services {
    li {
      font-size: .825rem;
      line-height: 2;
    }
  }
}
@media #{$desktop-xs-widths} {
  .about-us__what-is {
    flex-direction: column;
  }
  .about-us__time {
    padding: 11px 28px;
    p {
      font-size: 1rem;
    }
  }
}
@media #{$tablet-widths} {
  .about-us__img-block {
    display: table;
    width: 100%;
    height: 250px;
  }
  .about-us__list-services {
    display: table;
    width: 100%;
  }
  .swiper-container {
      .swiper-slide {
        &:hover{
          transform: scale(1);
        }
      }
  }
  .about-us__time {
    margin-left: 10px;
    margin-right: 10px;
  }
  .item-review {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .item-review__text-review {
    padding: 10px;
  }
  .item-review__descr {
    padding-top: 25px;
    text-align: center;
  }
}
@media #{$phone-widths} {
  .about-us__gallary {
    .swiper-button-prev {
      position: absolute;
      left: 0;
      z-index: 9999999;
    }
    .swiper-button-next {
      position: absolute;
      right: 0;
    }
  }
  .about-us__descr {
    h2 {
      font-size: 1rem;
    }
  }
  .about-us__list-services {
    li {
      font-size: .67rem;
      line-height: 2;
    }
  }
  .about-us__text-block {
    p {
      font-size: 1rem;
    }
  }
  .about-us__time {

    padding: 5px 8px;
    p {
      font-size: .7rem;
    }
  }
}
