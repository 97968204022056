.news-block {
  h1 {
    color: #fff;
    font-family: $g-black;
    font-size: 3rem;
    text-align: center;
  }
}
.news-block__wrapper {
  max-width: 780px;
  margin: auto;
}
.news-block__list {
  margin-top: 95px;
  text-align: center;
}
.news-block__item {
  display: flex;
  margin-bottom: 46px;
}
.news-block__img {
  img {
    max-width: inherit;
  }
}
.news-block__post-block {
  padding-left: 30px;
  text-align: left;
}
.news-block__descr {
  color: #fff;
  font-family: $g-bold;
  font-size: 1.5rem;
  line-height: 1;
}
.news-block__text-post {
  color: #fff;
  font-family: $g-reg;
  font-size: 1.125rem;
}
.news-block__date-post {
  display: block;
  padding:25px 0;
  color: #fff;
  font-family: $g-light;
  font-size: .875rem;
  line-height: 1;
}
.nav-posts {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

}
.nav-posts__item {
  display: inline-block;
  padding: 15px;
  border: 2px solid #17778b;
  color: #fff;
  font-family: $g-med;
  outline: none;
  cursor: pointer;
  &:hover{
    border-color: #36a3dd;
  }
}
@media #{$desktop-sm-widths} {
  .news-block__wrapper {
    margin:0 40px;
  }
}
@media #{$desktop-xs-widths} {
  .news-block__wrapper {
    margin:0 20px;
  }
  .news-block__item {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .news-block__post-block {
    margin-top: 50px;
    padding-left: 0px;
}
  .news-block__date-post {
    padding-left: 0;
  }
  .news-block__text-post {
    padding-left: 0;
  }
}

@media #{$phone-widths} {
  .news-block__wrapper {
    margin:0 5px;
  }
}
