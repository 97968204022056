.contacs-block__wrapper {
  width: 750px;
  margin: auto;
}
.contacs-block {
  h2 {
    color: #fff;
    font-family: $g-black;
    font-size: 3rem;
    text-align: center;
  }
}
.contacs-block__address {
  margin-top: 90px;
  li {
      display: flex;
      align-items: center;
      color: #fff;
      font-family: $g-reg;
      font-size: 1.125rem;
    a{
      color: #fff;
      font-family: $g-reg;
      font-size: 1.125rem;
    }
    .fa {
      margin-right: 38px;
      font-size: 2rem;
    }
    .fa-phone {
      margin-right: 30px;
    }
    &:nth-child(even){
      margin-top: 25px;
    }
  }
}
.contacs-block__map-block {
  margin-top: 88px;
  border: 2px solid #17778b;
}

@media #{$desktop-xs-widths} {
  .contacs-block__wrapper {
    width: inherit;
    margin: 0 40px;
  }
}
@media #{$tablet-widths} {
  .contacs-block__wrapper {
    margin: 0 20px;
  }

}
@media #{$phone-widths} {
  .contacs-block {
    h2 {
      font-size: 2rem;
    }
  }
  .contacs-block__wrapper {
    margin: 0 5px;
  }
  .contacs-block__address {
    li {
        font-size: 1rem;
      a{

        font-size: 1rem;
      }
      .fa {
        font-size: 1.5rem;
      }
    }
  }
}
