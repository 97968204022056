$phone-widths: "(max-width: 460px)";
$tablet-widths: "(max-width: 768px)";
$desktop-xs-widths: "(max-width: 992px)";
$desktop-sm-widths: "(max-width: 1200px)";
$desktop-widths: "(max-width: 1600px)";

$g-bold: GothaProBold, sans-serif;
$g-reg:  GothamProReg, sans-serif;
$g-black:  GothamProBlack, sans-serif;
$g-light:  GothamProLight, sans-serif;
$g-med:  GothamProMed, sans-serif;
$pn-black: ProximaNovaBlack, sans-serif;
$pn-bold: ProximaNovaBold, sans-serif;
