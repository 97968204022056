.error-404 {
    text-align: center;
    color: #fff;
    h1 {
        margin-bottom: 30px;
        font-family: $g-black;
        font-size: 48px;
    }
    p {
        font-family: $g-light;
        font-size: 30px;
    }
}

.wrapper-fish {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}
.err-text {
    font-family: $pn-bold;
    font-size: 15vw;
    color: #fff;
}
svg {
    width: 17vw;
}
.btn-main {
    display: inline-block;
    margin-top: 65px;
    padding: 15px;
    border: 2px solid #17778b;
    color: #fff;
    font-family: $g-med;
    outline: none;
    cursor: pointer;
    &:hover{
      border-color: #36a3dd;
    }
}
.btn-main:active,
.btn-main:link,
.btn-main:visited {
    color: #fff;
}
.eye {
    animation: eye 3s linear infinite alternate;
}
@keyframes eye {
    0% {
        transform: translate(0px);
    }
    37.5% {
        transform: translate(0px);
    }
    62.5% {
        transform: translate(-25px);
    }
    100% {
        transform: translate(-25px);
    }
}

@media #{$tablet-widths} {
  .wrapper-fish {
    margin-top: 50px;
  }
  .btn-main {
    margin-top: 30px;
  }
}
