.main-page {
    // position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.wave-img-1 {
    animation-name: wave1;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    max-width: inherit;
}
@keyframes wave1 {
    from {
        transform: translate(0px);
    }
    to {
        transform: translate(-140px);
    }
}
.wave-img-2 {
  margin-top: 20px;
    animation-name: wave2;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    float: right;
    max-width: inherit;
}
@keyframes wave2 {
    from {
        transform: translate(0px);
    }
    to {
        transform: translate(140px);
    }
}
.main-page__descr {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    text-align: center;
    h1 {
      img {
        width: 685px;
      }
      // font-size: 12.38rem;
      // line-height: 0.78;
      // font-family: $pn-black;
      // color: #fff;
      text-align: center;
        span {
            display: block;
            margin-bottom: 20px;
            padding-right: 6px;
            font-size: 16.13rem;
            line-height: 0.58;
        }
    }
    h2 {
        margin-top: 20px;
        color: #fff;
        font-family: $pn-bold;
        font-size: 2.188rem;
        line-height: 1;
        text-align: center;
    }
}
@media #{$tablet-widths} {
  .main-page__descr {
    // top: 0;
      h1 {
          font-size: 7.38rem;

          span {
              font-size: 10.13rem;
          }
      }
      h2 {
          font-size: 1.188rem;
          line-height: 1;
      }
  }
}
@media #{$phone-widths} {
  .main-page__descr {
      h1 {
          font-size: 4.38rem;

          span {
              font-size: 6.13rem;
          }
      }
      h2 {
          font-size: .85rem;
      }
  }
}
