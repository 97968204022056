/*
* Third Party
*/
@import "../../bower_components/normalize.css/normalize.css";
@import "../../bower_components/swiper/dist/css/swiper.min.css";
/*
* Custom
*/

@import "partials/_main";
